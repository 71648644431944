




























































import GoBackHeader from "@/components/shared/GoBackHeader.vue";
import { ADD_SURVEY_QUESTION } from "@/store/modules/recruiter/constants";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default Vue.extend({
  components: { GoBackHeader },
  name: "AddSurveyQuestion",
  data() {
    return {
      text: "",
      text_arabic: "",
      type: "",
      items: ["rating", "text-field", "closed-question"]
    };
  },
  methods: {
    ...mapActions("recruiter", {
      add_survey_question: ADD_SURVEY_QUESTION
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR,
      set_root_notification: ROOT_NOTIFICATION
    }),

    // Function to update company assessment setting
    async saveChanges() {
      // API Call
      const payload = {
        text: this.text,
        text_arabic: this.text_arabic,
        type: this.type
      };

      const response = await this.add_survey_question(payload);

      if (response) {
        this.set_root_notification(this.$t("survey_questions.survey-success"));
        this.$router.push("survey-questions");
      }
    }
  }
});
